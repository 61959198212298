.App {
    text-align: center;
    margin-bottom: 40px;
}

.App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
    font-size: 2em;
}

.search-form {
    margin: 20px;
}

.search-form input {
    margin: 5px;
    padding: 10px;
    font-size: 1em;
}

.search-form button {
    padding: 10px;
    font-size: 1em;
}

.logs-list {
    margin: 20px;
    text-align: left;
}

.log-entry {
    background-color: #f4f4f4;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}
